const imagePaths = {
    intro_background: require('../images/newintro.webp'),
    diamond_img: require('../images/newlogo.png'),
    text_icon: require('../images/dia_icon.png'),
    intro1: require('../images/intro1.png'),
    intro2: require('../images/introtwo.webp'),
    intro3: require('../images/introthree.webp'),
    intro4: require('../images/introfour.webp'),
    auth_background: require('../images/authback.webp'),
    dreamer_logo: require('../images/mainlogo.png'),
    dreamer_logo_new: require('../images/dreamerlatest.png'),
    auth_side_image: require('../images/auth_side_image.webp'),
    forgot_side_image: require('../images/forgot-side-image.webp'),
    otp_side_image: require('../images/otp_screen.webp'),
    change_password_side: require('../images/changePassword.webp'),
    dreamer_header: require('../images/dreamer_header.png'),
    dashboard_background: require('../images/dashboard-back.png'),
    profile: require('../images/profile.png'),
    black_diamond: require('../images/black-diamond.png'),
    background_completed: require('../images/exportcompleted.png'),
    background_ongoing: require('../images/exportongoing.png'),
    background_not_started: require('../images/exportnotstarted.png'),
    grid_one: require('../images/gridone.png'),
    grid_two: require('../images/first.png'),
    grid_three: require('../images/second.png'),
    grid_four: require('../images/third.png'),
    grid_five: require('../images/fourth.png'),
    demo_profile: require('../images/default_profile.png'),
    scanner: require('../images/scanner.png'),
    tree:require('../images/g10.png'),
    nofile:require('../images/nofile.png'),
    profile_back:require('../images/profileback.png'),
    play_svg:require('../images/playbutton.svg'),
    play:require('../images/playbutton.png'),
    shuffle:require('../images/shufflenew.png'),
    logohorizontal:require('../images/diamondhorlogo.png'),
    profilebackgrid:require('../images/profilebacknew.png'),
    logOutLogo:require('../images/transparentlogo.png'),
    graphsvg:require('../images/graphicon.svg'),
    // editicon:require('../images/editpencil.svg'),
  };
  
  export default imagePaths;