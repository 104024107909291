import React, { useEffect, useState } from 'react';
import './dashboard.css'
import { useNavigate } from 'react-router-dom';
import { abbreviateNumberDouble, convertSecondsToHoursLive, convertText, formatHoursLogged, getLocalUserDataByKey, logMessage } from '../../utils/helpers.js';
import { LuRadar } from "react-icons/lu";
import { FaRegCirclePlay, FaRegCirclePause } from "react-icons/fa6";
import { IoDiamondSharp } from "react-icons/io5";
import { MdTimerOff } from "react-icons/md";
import { RxCountdownTimer } from "react-icons/rx";
import Header from '../../components/common/header.js';
import imagePaths from '../../assets/imagePaths.js';
import paths from '../../utils/constants.js';
import api from '../../api/api.js';
import { ApiResponse } from '../../models/ApiResponseModel.js';
import { DataModel } from '../../models/projectModel.js';
import { convertSecondsToHours } from '../../utils/helpers.js';
import { GiStack } from 'react-icons/gi';
import { HomeModel } from '../../models/HomeModel.js';

const HomeScreen = ({ startLoading, stopLoading }) => {
    const navigate = useNavigate();
    const [type, setType] = useState("ongoing");
    const [liveTimer, setLiveTimer] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(0);
    const [search, setSearchKey] = useState("");
    const [name, setName] = useState("");
    const [profilePicture, setProfilePicture] = useState(imagePaths.demo_profile);
    const [projectData, setProjectData] = useState(new DataModel({}));

    const handleTypeClick = async (type) => {
        setType(type);
    }
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token || token.length <= 0) {
            navigate(paths.AUTH_SCREEN);
        }
    }, [navigate]);

    useEffect(() => {
        logMessage(liveTimer)
        if (liveTimer !== 0) {
            const intervalId = setInterval(() => {
                setLiveTimer(liveTimer + 1)
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [liveTimer]);

    const handleTimer = async (type, project_id) => {
        if (type == "stop") {
            stop_timer(project_id);
        } else {
            start_timer(project_id);
        }
        logMessage("handle timer clicked")
    }
    
    const start_timer = async (project_id) => {
        try {
            startLoading()
            const data = {
                "project_id": project_id
            };
            const response = await api.startTimer(data);
            const apiResponse = new ApiResponse(
                response.status,
                response.message
            );
            if (apiResponse.status === 1) {
                fetchProjects();
                stopLoading();
            } else {
                logMessage(apiResponse.message, 'alert');
            }
        } catch (error) {
            logMessage(error);
        } finally {
            stopLoading();
        }
    }
    const stop_timer = async (project_id) => {
        try {
            startLoading()
            const data = {
                "project_id": project_id
            };
            const response = await api.stopTimer(data);
            const apiResponse = new ApiResponse(
                response.status,
                response.message
            );
            if (apiResponse.status === 1) {
                fetchProjects()
                stopLoading();
            } else {
                logMessage(apiResponse.message, 'alert');
            }
        } catch (error) {
            logMessage(error);
        } finally {
            stopLoading();
        }
    }
    const openProjectDetail = (project_id) => {
        navigate(paths.PROJECT_DETAIL + "?project_id=" + project_id);
    }
    const handleChange = (e) => {
        setSearchKey(e.target.value);
    }
    const searchProducts = async () => {

        if (search !== "") {
            try {
                startLoading()
                const data = {
                    "search": search
                };
                const response = await api.searchProduct(data);
                const apiResponse = new ApiResponse(
                    response.status,
                    response.message
                );
                if (apiResponse.status === 1) {
                    setType("");
                    logMessage("project data before");
                    logMessage(response.data);
                    const projectModel = {
                        totalPages: 0,
                        currentPage: 1,
                        totalProjects: response.data.length,
                        hasNextPage: false,
                        projects: response.data
                    }
                    const projectData = new DataModel(projectModel);
                    logMessage("project data before");
                    logMessage(projectData);
                    // logMessage(projectData);
                    setProjectData(projectData)
                    stopLoading();
                } else {
                    logMessage(apiResponse.message, 'alert');
                }
            } catch (error) {
                logMessage(error);
            } finally {
                stopLoading();
            }
        }
    }
    const fetchProjects = async () => {
        // const email = getLocalUserDataByKey("user_email");
        setName(getLocalUserDataByKey("user_name"));
        logMessage(getLocalUserDataByKey("user_name"))
        if(getLocalUserDataByKey("profile_picture") != ""){
            setProfilePicture(getLocalUserDataByKey("profile_picture"));
        }
       
        setProjectData(new DataModel({}))
        try {            
            startLoading()
            // const data = {
            //     "type": type,
            //     "page": page,
            //     "limit": limit
            // };
            const response = await api.getHomeScreenData();
            const apiResponse = new ApiResponse(
                response.status,
                response.message
            );
            if (apiResponse.status === 1) {
                logMessage(response.data);
                const projectData = new HomeModel(response.data);
                setProjectData(projectData)
                stopLoading();
                const updatedProjects = projectData.projectList.map(project => {
                    if (project.timer_status === "started") {
                        setLiveTimer(project.totalDuration)
                    }
                });

            } else {
                logMessage(apiResponse.message, 'alert');
            }
        } catch (error) {
            logMessage(error);
        } finally {
            stopLoading();
        }
    };
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token || token.length <= 0) {
            navigate(paths.AUTH_SCREEN);
        }else{
        if (type !== "") {
            fetchProjects();
        }
    }
    }, [type]);
    return (
        <div className='full_page_inner'>
            <Header />
            <div className='dashboard_container' style={{ backgroundImage: `url(${imagePaths.dashboard_background})` }}>
                <div className="home_container">
                    <div className='home_banner'>
                        <div className='grids grid_one' style={{ backgroundImage: `url(${imagePaths.profilebackgrid})` }}>
                            <div className="left_inner_grid">
                                <img src={profilePicture}></img>
                            </div>
                            <div className="right_inner_grid">
                                <span className='welcome'>Welcome,</span><br></br><br></br>
                                <span className='home_name'>{name}</span>
                            </div>
                        </div>
                        <div className='grids grid_inner grid_two' style={{ backgroundImage: `url(${imagePaths.grid_two})` }}>
                            <div className="left_inner_grid">
                                <RxCountdownTimer />
                            </div>
                            <div className="right_inner_grid">
                                <span className='numberingText'>{projectData.hoursLogged ? formatHoursLogged(projectData.hoursLogged):0 }</span><br></br>
                                <span className='numberingName'>Hours Logged</span>
                            </div>
                        </div>
                        <div className='grids grid_inner grid_three' style={{ backgroundImage: `url(${imagePaths.grid_three})` }}>
                            <div className="left_inner_grid">
                                <IoDiamondSharp />
                            </div>
                            <div className="right_inner_grid">
                                <span className='numberingText'>{projectData.diamondsPlaced ? abbreviateNumberDouble(projectData.diamondsPlaced):0 }</span><br></br>
                                <span className='numberingName'>Diamonds Placed</span>
                            </div>
                        </div>
                        <div className='grids grid_inner grid_four' style={{ backgroundImage: `url(${imagePaths.grid_four})` }}>
                            <div className="left_inner_grid">
                                <GiStack />
                            </div>
                            <div className="right_inner_grid">
                                <span className='numberingText'>{projectData.totalCompleted ? projectData.totalCompleted:0 }</span><br></br>
                                <span className='numberingName'>Projects Completed</span>
                            </div>
                        </div>
                        <div className='grids grid_inner grid_five' style={{ backgroundImage: `url(${imagePaths.grid_five})` }}>
                            <div className="left_inner_grid">
                                <MdTimerOff />
                            </div>
                            <div className="right_inner_grid">
                                <span className='numberingText'>{projectData.projectsNotStarted ? projectData.projectsNotStarted:0 }</span><br></br>
                                <span className='numberingName'>Projects not started</span>
                            </div>
                        </div>
                    </div>
                    <div className='inner_container'>
                        <div className='table_container home_container_table'>
                            <h4 className='table_title'>Current Projects</h4>
                            <div className='table_inner_card hometable'>
                                {projectData.projectList && projectData.projectList.length > 0 ? (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Design</th>
                                                <th>Artist</th>
                                                <th>Brand</th>
                                                <th>Logged time</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {projectData.projectList.length > 0 ? (
                                                projectData.projectList.map(project => (
                                                    <tr key={project._id} onClick={() => openProjectDetail(project._id)}>
                                                        <td><img alt="project_image" className="table_image" src={project.image[0]}></img></td>
                                                        <td className='design_text'>{project.design}</td>
                                                        <td>© {project.artist}</td>
                                                        <td>{project.brand}</td>
                                                        <td className='timesection'> {project && (
                                                            <>
                                                                {project.timer_status === "started" ? (
                                                                    <>
                                                                        <FaRegCirclePause
                                                                            className="timer_duration"
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                handleTimer("stop", project._id);
                                                                            }}
                                                                        />
                                                                        {convertSecondsToHoursLive(liveTimer)}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <FaRegCirclePlay
                                                                            className="timer_duration"
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                handleTimer("start", project._id);
                                                                            }}
                                                                        />
                                                                        {convertSecondsToHours(project.totalDuration)}
                                                                    </>
                                                                )}
                                                            </>
                                                        )} </td>
                                                        <td className='statusProject'><span className={project.project_status}> <LuRadar /> {convertText(project.project_status)}</span></td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <p>No ongoing projects found.</p>
                                            )}

                                        </tbody>
                                    </table>
                                ) : (
                                    <p style={{ textAlign: 'center' }}>No projects found.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeScreen;