import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import { logMessage, signOutUser } from "../../utils/helpers.js";
import { TbCircleKey } from "react-icons/tb";
import { GoShieldCheck } from "react-icons/go";
import Header from "../../components/common/header.js";
import imagePaths from "../../assets/imagePaths.js";
import paths from "../../utils/constants.js";
import api from "../../api/api.js";
import { ApiResponse } from "../../models/ApiResponseModel.js";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { FiUserX } from "react-icons/fi";
import { MdOutlinePlaylistAddCheckCircle } from "react-icons/md";
import { FaCamera, FaEye, FaEyeSlash } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { UserData } from "../../models/UserModel.js";

const MyProfile = ({ startLoading, stopLoading }) => {
  const navigate = useNavigate();
  const [type, setType] = useState("personal");
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState();
  const [imageChange, setImageChange] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem("user_email"));
  const [profile, setProfile] = useState();
  // const [localImage, setLocalImage] = useState("");
  const [userData, setUserData] = useState({
    old_password: "",
    new_password: "",
    confirmpassword: "",
  });

  const [editFormData, setEditFormData] = useState({
    name: "",
    image: "",
  });

  const togglePopup = () => {
    logMessage("toggleclicked");
    setShowPopup(!showPopup);
    logMessage(showPopup);
  };

  const toggleNewPasswordVisibility = (passwordType) => {
    if (passwordType == "current") {
      setShowCurrentPassword(!showCurrentPassword);
    } else if (passwordType == "new") {
      setShowNewPassword(!showNewPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const deletePopUp = () => {
    <div className="popup-overlay">
      <div className="popup">
        <h2>Delete Account</h2>
        <p>Are you sure you want to delete account</p>
        <div className="warning">
          <strong>Warning</strong>
          <p>
            By deleting the account you will lose all your projects data and
            media
          </p>
        </div>
        <button className="delete-button">Yes, Delete Account</button>
        <button className="cancel-button" onClick={togglePopup}>
          Cancel
        </button>
      </div>
    </div>;
  };

  const Option = ({ calling, label, icon: Icon }) => {
    return (
      <div onClick={() => optionClick(calling)} className="settings-option">
        <div className="setting_labeled">
          <span className="icon_class">{Icon}</span>
          <span>{label}</span>
        </div>
        <span className="settings-option-arrow">›</span>
      </div>
    );
  };

  const handleChange = (typeSet) => {
    setType(typeSet);
  };

  const optionClick = (calling) => {
    switch (calling) {
      case "changePassword":
        setType("change_password");
        break;
      case "terms":
        // navigate(paths.TERMS);
        window.open(paths.TERMS, "_blank");
        break;
      case "privacy":
        // navigate(paths.PRIVACY);
        window.open(paths.PRIVACY, "_blank");
        break;
      case "support":
        // navigate(paths.PRIVACY);
        window.open("mailto:support@dreamerdesigns.com", "_blank");
        break;
      // case "about":
      //   // navigate(paths.ABOUTUS);
      //   window.open(paths.ABOUTUS, '_blank');
      //   break;
      case "delete":
        togglePopup();
        break;
      default:
        break;
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    setEditFormData((prevFormData) => ({
      ...prevFormData,
      name: event.target.value,
    }));
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const getTypeComponent = (type) => {
    const components = {
      personal: () => renderPersonalInfo(),
      settings: () => renderSettingsOptions(),
      change_password: () => changePasswordInfo(),
      // Add more types and corresponding rendering options here
    };

    const component = components[type];
    if (!component) {
      console.error(`Invalid type: ${type}`);
      return null;
    }

    return component();
  };

  const handleEditProfile = async (event) => {
    logMessage(editFormData);
    event.preventDefault();
    startLoading();
    try {
      const response = await api.editProfile(editFormData, imageChange);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status == 1) {
        // stopLoading();
        setTimeout(() => {
          getProfileData();
        }, 1000);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      // stopLoading();
    }
  };

  const handleChangePassword = async (event) => {
    logMessage(editFormData);
    event.preventDefault();
    if (userData.confirmpassword != userData.new_password) {
      logMessage("Your confirm password mismatches with new password", "alert");
    } else {
      try {
        startLoading();
        const response = await api.changePassword(userData);
        const apiResponse = new ApiResponse(response.status, response.message);
        if (apiResponse.status == 1) {
          stopLoading();
          setUserData({
            old_password: "",
            new_password: "",
            confirmpassword: "",
          });
          setType("settings");
        } else {
          logMessage(apiResponse.message, "alert");
        }
      } catch (error) {
        logMessage(error);
      } finally {
        stopLoading();
      }
    }
  };

  const renderPersonalInfo = () => (
    <div className="personal_info">
      <form onSubmit={handleEditProfile}>
        <div className="form_container personal_form">
          <div className="form_fields">
            <label>Name:</label>
            <input
              type="text"
              name="design"
              onChange={handleNameChange}
              value={editFormData.name}
            />
          </div>
          <div className="form_fields">
            <label>Email:</label>
            <input
              type="text"
              style={{ color: "#808080" }}
              name="email"
              defaultValue={email}
              readOnly
            />
          </div>
        </div>
        <div className="button_fields">
          <button className="addProject" type="submit">
            Update
          </button>
        </div>
      </form>
    </div>
  );

  const changePasswordInfo = () => (
    <div className="personal_info changePassword">
      <p className="settingspassword">Settings {">"} Change password</p>
      <form onSubmit={handleChangePassword}>
        <div className="form_container personal_form">
          <div className="form_fields currentpassword">
            <label>Current password:</label>
            {/* <div> */}
            <input
              type={showCurrentPassword ? "text" : "password"}
              placeholder="Password"
              name="old_password"
              value={userData.old_password}
              onChange={handleFormChange}
              required
            />
            <button
              className="passwordEye"
              type="button"
              onClick={() => toggleNewPasswordVisibility("current")}
            >
              {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
            {/* </div> */}
          </div>
          <div className="form_fields">
            <label>New password:</label>
            <input
              type={showNewPassword ? "text" : "password"}
              placeholder="New"
              name="new_password"
              value={userData.new_password}
              onChange={handleFormChange}
              required
            />
            <button
              className="passwordEye"
              type="button"
              onClick={() => toggleNewPasswordVisibility("new")}
            >
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="form_fields">
            <label>Confirm password:</label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm"
              name="confirmpassword"
              value={userData.confirmpassword}
              onChange={handleFormChange}
              required
            />
            <button
              className="passwordEye"
              type="button"
              onClick={() => toggleNewPasswordVisibility("confirm")}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
        <div className="button_fields">
          <button className="addProject changeProfilePassword" type="submit">
            Change
          </button>
        </div>
      </form>
    </div>
  );

  const renderSettingsOptions = () => (
    <div className="settings_options">
      <Option
        calling="changePassword"
        label="Change Password"
        icon={<TbCircleKey />}
      />
      <Option calling="support" label="Support" icon={<GoShieldCheck />} />
      {/* <Option
        calling="about"
        label="About"
        icon={<IoIosInformationCircleOutline />}
      /> */}
      <Option
        calling="terms"
        label="Terms & Conditions"
        icon={<MdOutlinePlaylistAddCheckCircle />}
      />
      <Option
        calling="privacy"
        label="Privacy Policy"
        icon={<HiOutlineClipboardDocumentCheck />}
      />
      <Option calling="delete" label="Delete Account" icon={<FiUserX />} />
    </div>
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageChange(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        setEditFormData((prevFormData) => ({
          ...prevFormData,
          // image: [...prevFormData.image, e.target.result]
          image: file,
        }));
        setProfile(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const deleteAccount = async () => {
    try {
      startLoading();
      const response = await api.deleteAccount();
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        signOutUser(navigate);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  const getProfileData = async () => {
    try {
      startLoading();
      const response = await api.getProfile();
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        const signUpResponse = new UserData(response.data);
        stopLoading();
        localStorage.setItem("user_data", JSON.stringify(signUpResponse.data));
        localStorage.setItem("user_email", signUpResponse.email);
        localStorage.setItem("user_name", signUpResponse.name);
        localStorage.setItem("profile_picture", signUpResponse.profile_picture);
        logMessage("Update runs");
        logMessage(signUpResponse.profile_picture);

        // setTimeout(() => {
        setName(signUpResponse.name);
        setEditFormData({
          image: signUpResponse.profile_picture,
          name: signUpResponse.name,
        });
        setProfile(signUpResponse.profile_picture);
        // }, 1000);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    getProfileData();
  }, []);

  const uploadImageClick = (e) => {
    e.preventDefault();
    logMessage("clicked");
    document.getElementById("imageUploadInput").click();
  };

  return (
    <div className="full_page_inner">
      <Header />

      <div
        className="dashboard_container profile_container"
        style={{ backgroundImage: `url(${imagePaths.profile_back})` }}
      >
        <div className="home_container profile_outer_container">
          {showPopup && (
            <div className="popup-overlay">
              <div className="popup">
                <h2>Delete Account</h2>
                <p>Are you sure you want to delete account</p>
                <div className="warning">
                  <div className="warningtextbox">
                  <p className="warningtext">
                    <IoIosWarning /> Warning
                  </p>
                  </div>
                  
                  <p>
                  By deleting the account you will loose all your projects data and media
                  </p>
                </div>
                <button className="delete-button" onClick={deleteAccount}>
                Yes, Delete Account
                </button>
                <button className="cancel-button canceldelacc" onClick={togglePopup}>
                  Cancel
                </button>
              </div>
            </div>
          )}
          <div className="profile_inner_container">
            <div className="left_profile_container">
              <div onClick={uploadImageClick} className="image box">
                <img src={profile || imagePaths.demo_profile} alt="Profile" />
                <div className="camera-icon">
                  <FaCamera />
                </div>
              </div>
              <input
                style={{ display: "none" }}
                type="file"
                id="imageUploadInput"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
              />
              <p
                style={{ fontSize: "22px", fontWeight: "500" }}
                className="name userprofilename"
              >
                {name}
              </p>
              <p style={{ fontSize: "16px" }} className="email userprofileemail">
                {email}
              </p>
            </div>
            <div className="right_profile_container">
              <div className="tabs_profile">
                <div
                  onClick={() => handleChange("personal")}
                  className={type === "personal" ? "tab active" : "tab"}
                >
                  Personal Information
                </div>
                <div
                  onClick={() => handleChange("settings")}
                  className={type === "settings" ? "tab active" : "tab"}
                >
                  Settings
                </div>
              </div>
              <div className="info_content">
                {getTypeComponent(type)}
                {/* {type === 'personal' ? (
                                    <div className='personal_info'>
                                        <form>
                                            <div className="form_container perosnal_form">
                                                <div className='form_fields'>
                                                    <label>Name:</label>
                                                    <input type="text" name="design" value={name} />
                                                </div>
                                                <div className='form_fields'>
                                                    <label>Email:</label>
                                                    <input type="text" name="artist" value={email} readOnly />
                                                </div>
                                            </div>

                                            <button className="addProject" type="submit">Update</button>
                                        </form>
                                    </div>
                                ) : (
                                    <div className="settings_options">
                                        <Option label="Change Password" icon={<TbCircleKey />} />
                                        <Option label="Support" icon={<GoShieldCheck />} />
                                        <Option label="About" icon={<IoIosInformationCircleOutline />} />
                                        <Option label="Terms & Conditions" icon={<MdOutlinePlaylistAddCheckCircle />} />
                                        <Option label="Privacy Policy" icon={<HiOutlineClipboardDocumentCheck />} />
                                        <Option label="Delete Account" icon={<FiUserX />} />
                                    </div>
                                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
