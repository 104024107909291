//Header.js
import './common.css';
import React, { useEffect, useState } from 'react';
import imagePaths from '../../assets/imagePaths';
import { FaHome, FaAngleDown, FaRegUserCircle } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { FaShuffle } from "react-icons/fa6";
import { GiStack } from "react-icons/gi";
import { CiShop } from "react-icons/ci";
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { checkAuthorizationToken, getLocalUserDataByKey } from '../../utils/helpers.js';
import { logMessage } from '../../utils/helpers';
import { signOutUser } from '../../utils/helpers.js';
import paths from '../../utils/constants.js';

const Header = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState(imagePaths.demo_profile);
  const name = localStorage.getItem("user_name");
  const toggleDropdown = () => {
    logMessage("clicked");
    setIsOpen(!isOpen);
  };

  const handleSignOut = () => {
    logMessage("outsideclicked");
    signOutUser(navigate)
  };

  const gotoMyProfile = () => {
    logMessage("outsideclicked");
    navigate(paths.MY_PROFILE);
  };
  const gotoSupport = () => {
    window.open('mailto:support@dreamerdesigns.com', '_blank');

  };
  
  const handleClickOutside = (event) => {
    logMessage("outsideclicked");
    if (event.target.classList.contains('menuInner') || event.target.classList.contains('dropbtn')) {
    } else {
      logMessage("inside menu outside");
      setIsOpen(false);
    }
  };
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token || token.length <= 0) {
        navigate(paths.AUTH_SCREEN);
    }
}, [navigate]);

  useEffect(() => {
    if(getLocalUserDataByKey("profile_picture") !== ""){
      setProfilePicture(getLocalUserDataByKey("profile_picture"));
  }
    checkAuthorizationToken(navigate)
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  return (
    <header>
      <div className="header-left">
        <img src={imagePaths.logohorizontal} alt="Logo" />
      </div>
      <div className="header-right header-center">
        <nav>
          <ul>
            <li><NavLink exact to={paths.HOME_SCREEN}><FaHome /> Home</NavLink></li>
            <li><NavLink exact to={paths.PROJECT_SCREEN}><GiStack /> Projects</NavLink></li>
            <li><NavLink exact to={paths.SHUFFLE}><FaShuffle />Shuffle</NavLink></li>
            {/* <li><a href="#"><FaShuffle />Shuffle</a></li> */}
            <li><NavLink exact to={paths.SHOP}><CiShop /> Shop</NavLink></li>
          </ul>
        </nav>
        <div className="vertical-line"></div>
        <img src={profilePicture} alt="profilepicture" className="header_profile"></img>
        <span className='headerProfile'>{name}</span>
        <FaAngleDown className="dropbtn" onClick={toggleDropdown} />
        {isOpen && (
          <div className="dropdown-content header-dropdown">
            <p className="menuInner" onClick={gotoMyProfile} ><FaRegUserCircle />  My Profile</p>
            {/* <p className="menuInner" onClick={goToAboutUs} ><FiInfo />  About</p> */}
            <p className="menuInner" onClick={gotoSupport}><BiSupport />  Support</p>
            <p className="menuInner" onClick={handleSignOut}><RiLogoutCircleRLine />  Log Out</p>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
