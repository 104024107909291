import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertSecondsToHoursLive,
  convertText,
  logMessage,
} from "../../utils/helpers.js";
import { LuRadar } from "react-icons/lu";
import { FaRegCirclePlay, FaRegCirclePause } from "react-icons/fa6";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import Header from "../../components/common/header.js";
import imagePaths from "../../assets/imagePaths.js";
import paths from "../../utils/constants.js";
import api from "../../api/api.js";
import {
  AiOutlineClose,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { ApiResponse } from "../../models/ApiResponseModel.js";
import { DataModel } from "../../models/projectModel.js";
import { convertSecondsToHours } from "../../utils/helpers.js";
import { BiSort } from "react-icons/bi";

const SearchScreen = ({ startLoading, stopLoading }) => {
  const navigate = useNavigate();
  const [type, setType] = useState("completed");
  const [sortPattern, setSortPattern] = useState("");
  const [sortType, setSortType] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    type: "",
    sort: "",
    diamondType: "",
  });
  const [diamondTypeSort, setDiamondTypeSort] = useState("");
  const [liveTimer, setLiveTimer] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [sortTypeKey, setSortTypeKey] = useState("");
  const [search, setSearchKey] = useState("");
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const searchString = queryParams.get("search");
  const [projectData, setProjectData] = useState(new DataModel({}));
  const [showUI, setShowUI] = useState(true);

  const handleTypeClick = async (type) => {
    setType(type);
  };
  const addProject = () => {
    navigate(paths.ADD_PROJECT);
  };
  useEffect(() => {
    logMessage(liveTimer);
    if (liveTimer !== 0) {
      const intervalId = setInterval(() => {
        setLiveTimer(liveTimer + 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [liveTimer]);
  const handleTimer = async (type, project_id) => {
    if (type == "stop") {
      stop_timer(project_id);
    } else {
      start_timer(project_id);
    }
    logMessage("handle timer clicked");
  };
  const start_timer = async (project_id) => {
    try {
      startLoading();
      const data = {
        project_id: project_id,
      };
      const response = await api.startTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        searchProducts();
        stopLoading();
        setShowUI(true);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
      setShowUI(true);
    }
  };
  const stop_timer = async (project_id) => {
    try {
      startLoading();
      const data = {
        project_id: project_id,
      };
      const response = await api.stopTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        searchProducts();
        stopLoading();
        setShowUI(true);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  const handleSort = async () => {
    await togglePopup();
    logMessage("sortPattern" + sortPattern);
    const sortData = {
      type: sortPattern,
      diamondType: diamondTypeSort,
      sort: sortType,
    };
    await setSelectedFilters(sortData);
    // await searchProducts();
  };

  const openProjectDetail = (project_id) => {
    navigate(paths.PROJECT_DETAIL + "?project_id=" + project_id);
  };
  const handleChange = (e) => {
    setSearchKey(e.target.value);
  };

  const handleSortButtons = (pattern, type) => {
    setSortPattern(pattern);
    setSortType(type);
    if (type == "created_at") {
      setSortTypeKey("Date Modified");
    } else {
      setSortTypeKey(type);
    }
  };
  //   const handleArtistSort = (type) => {
  //     setArtistSort(type);
  //   };
  //   const handleBrandSort = (typee) => {
  //     setBrandSort(type);
  //   };
  const handleDiamondType = (type) => {
    setDiamondTypeSort(type);
  };
  const clearFilter = async (type) => {
    if (type == "diamondType") {
      await setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        diamondType: "",
      }));
      await setDiamondTypeSort("");
      //  await searchProducts()
    } else {
      await setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        sort: "",
      }));
      await setSortPattern("");
      await setSortType("");
      //  await searchProducts()
    }
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      await searchProducts();
    }, 1000); // 1000 milliseconds delay

    return () => clearTimeout(timer);
  }, [selectedFilters]);

  const searchProductsAll = async (searchFromUrl) => {
    logMessage("insidesearch");
    logMessage(searchFromUrl);
    if (searchFromUrl !== "") {
      logMessage(searchFromUrl);
      try {
        startLoading();
        const data = {
          search: searchFromUrl,
        };
        const response = await api.searchProduct(data);
        const apiResponse = new ApiResponse(response.status, response.message);
        if (apiResponse.status === 1) {
          setType("");
          logMessage("project data before");
          logMessage(response.data);
          const projectModel = {
            totalPages: 0,
            currentPage: 1,
            totalProjects: response.data.length,
            hasNextPage: false,
            projects: response.data,
          };
          const projectData = new DataModel(projectModel);
          logMessage("project data before");
          logMessage(projectData);
          // logMessage(projectData);
          setProjectData(projectData);
          const updatedProjects = projectData.projects.map((project) => {
            if (project.timer_status === "started") {
              setLiveTimer(project.totalDuration);
            }
          });
          stopLoading();
          setShowUI(true);
        } else {
          logMessage(apiResponse.message, "alert");
        }
      } catch (error) {
        logMessage(error);
      } finally {
        stopLoading();
        setShowUI(true);
      }
    }
  };
  const searchProducts = async () => {
    logMessage("insidesearch");
    logMessage(search);
    if (search !== "") {
      logMessage(search);
      try {
        startLoading();

        const data = {
          search: search,
          sort: selectedFilters.sort,
          type: selectedFilters.type,
          diamondType: selectedFilters.diamondType,
        };
        logMessage(data);
        const response = await api.searchProduct(data);
        const apiResponse = new ApiResponse(response.status, response.message);
        if (apiResponse.status === 1) {
          setType("");
          logMessage("project data before");
          logMessage(response.data);
          const projectModel = {
            totalPages: 0,
            currentPage: 1,
            totalProjects: response.data.length,
            hasNextPage: false,
            projects: response.data,
          };
          const projectData = new DataModel(projectModel);
          logMessage("project data before");
          logMessage(projectData);
          // logMessage(projectData);
          setProjectData(projectData);
          const updatedProjects = projectData.projects.map((project) => {
            if (project.timer_status === "started") {
              setLiveTimer(project.totalDuration);
            }
          });
          stopLoading();
          setShowUI(true);
        } else {
          logMessage(apiResponse.message, "alert");
        }
      } catch (error) {
        logMessage(error);
      } finally {
        stopLoading();
        setShowUI(true);
      }
    }
  };
  const togglePopup = () => {
    logMessage("toggleclicked");
    setShowPopup(!showPopup);
    logMessage(showPopup);
  };

  const fetchProjects = async () => {
    setProjectData(new DataModel({}));
    try {
      startLoading();
      const data = {
        type: type,
        page: page,
        limit: 100000,
      };
      const response = await api.getProjectList(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        // logMessage(response.data);
        const projectData = new DataModel(response.data);
        const updatedProjects = projectData.projects.map((project) => {
          if (project.timer_status === "started") {
            setLiveTimer(project.totalDuration);
          }
        });
        setProjectData(projectData);
        stopLoading();
        setShowUI(true);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
      setShowUI(true);
    }
  };
  // useEffect(() => {
  // if (searchString !== "") {
  // logMessage("jhdgfh");
  // logMessage(searchString);
  // setSearchKey(searchString);
  // searchProductsAll(searchString);
  // }
  // }, [searchString]);

  return (
    <div className="full_page_inner">
      <Header />
      <div
        className="dashboard_container"
        style={{ backgroundImage: `url(${imagePaths.dashboard_background})` }}
      >
        <div className="home_container">
          {showPopup && (
            <div className="popup-overlay">
              <div className="popup newsortpop">
                <div className="popup_header">
                  <h2 className="sortbyTitle">Sort By</h2>
                </div>
                <div className="popbodycontent popbodycontentsort">
                  <div className="popup_row sort_pop_row">
                    <p className="sortTitle">Date Modified</p>
                    <div className="rightsortgroup">
                      <p
                        onClick={() => handleSortButtons("desc", "created_at")}
                        className={`sortbuttons desc ${
                          sortPattern === "desc" && sortType === "created_at"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        Newest
                      </p>
                      <p
                        onClick={() => handleSortButtons("asc", "created_at")}
                        className={`sortbuttons asc ${
                          sortPattern === "asc" && sortType === "created_at"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        Oldest
                      </p>
                    </div>
                  </div>
                  <div className="popup_row sort_pop_row">
                    <p className="sortTitle">Design</p>
                    <div className="rightsortgroup">
                      <p
                        onClick={() => handleSortButtons("asc", "design")}
                        className={`sortbuttons asc ${
                          sortPattern === "asc" && sortType === "design"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        <AiOutlineSortAscending />
                      </p>
                      <p
                        onClick={() => handleSortButtons("desc", "design")}
                        className={`sortbuttons desc ${
                          sortPattern === "desc" && sortType === "design"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        <AiOutlineSortDescending />
                      </p>
                    </div>
                  </div>
                  <div className="popup_row sort_pop_row">
                    <p className="sortTitle">Artist</p>
                    <div className="rightsortgroup">
                      <p
                        onClick={() => handleSortButtons("asc", "artist")}
                        className={`sortbuttons asc ${
                          sortPattern === "asc" && sortType === "artist"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        <AiOutlineSortAscending />
                      </p>
                      <p
                        onClick={() => handleSortButtons("desc", "artist")}
                        className={`sortbuttons desc ${
                          sortPattern === "desc" && sortType === "artist"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        <AiOutlineSortDescending />
                      </p>
                    </div>
                  </div>
                  <div className="popup_row sort_pop_row">
                    <p className="sortTitle">Brand</p>
                    <div className="rightsortgroup">
                      <p
                        onClick={() => handleSortButtons("asc", "brand")}
                        className={`sortbuttons asc ${
                          sortPattern === "asc" && sortType === "brand"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        <AiOutlineSortAscending />
                      </p>
                      <p
                        onClick={() => handleSortButtons("desc", "brand")}
                        className={`sortbuttons desc ${
                          sortPattern === "desc" && sortType === "brand"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        <AiOutlineSortDescending />
                      </p>
                    </div>
                  </div>
                  <div className="popup_row sort_pop_row lastrow">
                    <p className="sortTitle">Diamond Type</p>
                    <div className="rightsortgroup">
                      <p
                        onClick={() => handleDiamondType("square")}
                        className={`sortbuttons asc ${
                          diamondTypeSort === "square" ? "sortSelected" : ""
                        }`}
                      >
                        Square
                      </p>
                      <p
                        onClick={() => handleDiamondType("round")}
                        className={`sortbuttons desc ${
                          diamondTypeSort === "round" ? "sortSelected" : ""
                        }`}
                      >
                        Round
                      </p>
                    </div>
                  </div>
                  <button className="delete-button" onClick={handleSort}>
                    Sort
                  </button>
                  {/* <button className="cancel-button" onClick={togglePopup}>
                    Cancel
                  </button> */}
                </div>
              </div>
            </div>
          )}
          <div className="outer_banner">
            <div className="mid_banner" style={{ position: "initial" }}>
              <div className="left_mid_container" style={{ width: "86%" }}>
                <div style={{ width: "100%" }} className="search-container">
                  <PiMagnifyingGlassLight
                    className="searchIcon"
                    onClick={() => searchProducts()}
                  />
                  <input
                    type="text"
                    value={search}
                    className="searchBox"
                    onChange={handleChange}
                    placeholder="Search"
                  ></input>
                </div>
              </div>
              <div className="right_mid_container">
                <div className="inner_right_group">
                  {/* <input type='text' className='searchBox' placeholder='Search'></input> */}
                  <span
                    style={{
                      background: "transparent",
                      color: "#552f83",
                      border: "#552f83 1px solid",
                    }}
                    onClick={togglePopup}
                  >
                    Sort By <BiSort />
                  </span>
                </div>
              </div>
            </div>
            <div className="filters">
              {selectedFilters.sort && (
                <div className="sortType sortFilters">
                  {selectedFilters.type === "asc" && <AiOutlineSortAscending />}
                  {selectedFilters.type === "desc" && (
                    <AiOutlineSortDescending />
                  )}
                  <>
                    {sortTypeKey}
                    <AiOutlineClose
                      onClick={() => clearFilter("sort")}
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                  </>
                </div>
              )}
              {selectedFilters.diamondType && (
                <div className="diamondType sortType">
                  {selectedFilters.diamondType}{" "}
                  <AiOutlineClose
                    onClick={() => clearFilter("diamondType")}
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="inner_container">
            <div className="table_container" style={{ marginTop: "0px" }}>
              <div className="table_inner_card">
                {/* <div className='button_group_dashboard '>
                                    <span className={type === 'completed' ? 'activespan' : ''} onClick={() => handleTypeClick('completed')}>Completed</span>
                                    <span className={type === 'ongoing' ? 'activespan' : ''} onClick={() => handleTypeClick('ongoing')}>Work-in-progress</span>
                                    <span className={type === 'not_started' ? 'activespan' : ''} onClick={() => handleTypeClick('not_started')}>Not Started</span>
                                </div> */}
                {projectData.projects.length > 0 ? (
                  <table className="searchresultstable">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Design</th>
                        <th>Artist</th>
                        <th>Brand</th>
                        <th>Logged time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectData.projects.length > 0 ? (
                        projectData.projects.map((project) => (
                          <tr
                            key={project._id}
                            onClick={() => openProjectDetail(project._id)}
                          >
                            <td>
                              <img
                                alt="project_image"
                                className="table_image"
                                src={project.image[0]}
                              ></img>
                            </td>
                            <td className="design_text">{project.design}</td>
                            <td>© {project.artist}</td>
                            <td>{project.brand}</td>
                            <td>
                              {" "}
                              {project && (
                                <>
                                  {project.timer_status === "started" ? (
                                    <>
                                      <FaRegCirclePause
                                        className="timer_duration"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleTimer("stop", project._id);
                                        }}
                                      />
                                      {convertSecondsToHoursLive(liveTimer)}
                                    </>
                                  ) : (
                                    <>
                                      <FaRegCirclePlay
                                        className="timer_duration"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleTimer("start", project._id);
                                        }}
                                      />
                                      {convertSecondsToHours(
                                        project.totalDuration
                                      )}
                                    </>
                                  )}
                                </>
                              )}{" "}
                            </td>
                            <td>
                              <span className={project.project_status}>
                                {" "}
                                <LuRadar />{" "}
                                {convertText(project.project_status)}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="no_project">
                          {showUI && (
                            <div>
                              <img src={imagePaths.nofile} alt="No projects" />
                              <p>
                                No results, Search design, artist, brand, diamond shape or
                                tags.
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </tbody>
                  </table>
                ) : (
                  // {showUI ? &&(
                  <div className="no_project">
                    {showUI && (
                      <div>
                        <img src={imagePaths.nofile} alt="No projects" />
                        <p>
                          Search design, artist, brand, diamond shape or
                          tags.
                        </p>
                      </div>
                    )}
                  </div>
                  // )}
                  // </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchScreen;
