import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { RxDimensions } from "react-icons/rx";
import Header from "../../components/common/header.js";
import imagePaths from "../../assets/imagePaths.js";
import {
  logMessage,
} from "../../utils/helpers.js";
import api from "../../api/api.js";
import { ApiResponse } from "../../models/ApiResponseModel.js";
import { Project } from "../../models/projectModel.js";
import paths from "../../utils/constants.js";
import currency from "../../utils/currencyConstants.js";
import { Scan } from "../../models/ScanModel.js";

const EditProject = ({ startLoading, stopLoading }) => {
  const navigate = useNavigate();
  const [tag, setTag] = useState("");
  const [scannedResult, setScannedResult] = useState("");
  const [barcode, setBarcode] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project_id");
  if(projectId == undefined){
      alert("invalid project")
  }
  const [isOpen, setIsOpen] = useState("");
  const [localImage, setLocalImages] = useState([]);
  const [formData, setFormData] = useState({
    design: "",
    image: [],
    project_id:"",
    artist: "",
    brand: "",
    canvas_width: 0,
    canvas_height: 0,
    diamond_type: "",
    no_of_color: "",
    abs: 0,
    glow: 0,
    crystal: 0,
    special_status: false,
    country_code: "",
    currency_code: "",
    total_amount_diamonds: 0,
    price: 0,
    extraNotes: "",
    startDate: "",
    endDate: "",
    tags: [],
  });

  const handleTagChange = (e) => {
    setTag(e.target.value);
  };

  const fetchProjectDetail = async () => {
    try {
      startLoading();
      const data = {
        project_id: projectId,
      };
      const response = await api.getProjectDetail(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        logMessage(response.data);
        const projectData = new Project(response.data)
        logMessage("countrycode"+projectData.country_code)
        setLocalImages(projectData.image)
        
        setFormData({
          design: projectData.design,
          project_id: projectId,
          image: projectData.image,
          artist: projectData.artist,
          brand: projectData.brand,
          canvas_width: projectData.canvas_width,
          canvas_height: projectData.canvas_height,
          diamond_type: projectData.diamond_type,
          no_of_color: projectData.no_of_color,
          abs: projectData.abs || 0,
          glow: projectData.glow|| 0,
          crystal: projectData.crystal || 0,
          special_status: projectData.special_status,
          country_code: projectData.country_code,
          currency_code: projectData.currency_code,
          total_amount_diamonds:
          projectData.total_amount_diamonds || 0,
          price: parseFloat(projectData.price || 0),
          extraNotes: projectData.extraNotes,
          startDate:projectData.startDate,
          endDate: projectData.endDate,
          tags: projectData.tags,
        });
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if(token && token.length <= 0){
      navigate(paths.AUTH_SCREEN);
    }else{
      logMessage("editprojectcalled")
      fetchProjectDetail();
    }
   
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          // image: [...prevFormData.image, e.target.result]
          image: [...prevFormData.image, file]
          // image: [file],
        }));
        setLocalImages((prevImages) => [...prevImages, e.target.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          image: [...prevFormData.image, file]
          // image: [...prevFormData.image, e.target.result]
          // image: [file],
        }));
        setLocalImages((prevImages) => [...prevImages, e.target.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddProject = async (event) => {
    logMessage(formData);
    event.preventDefault();
    startLoading();
    try {
      const response = await api.editProject(formData);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status == 1) {
        // const signUpResponse = new UserData(response.data);
        stopLoading();
        // localStorage.setItem('user_data', JSON.stringify(signUpResponse.data));
        // localStorage.setItem('user_email', signUpResponse.email);
        // localStorage.setItem('user_name', signUpResponse.name);
        // localStorage.setItem('profile_picture', signUpResponse.profile_picture);
        navigate(paths.PROJECT_SCREEN);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };

  const addTag = (e) => {
    e.preventDefault();
    setFormData((prevFormData) => ({
      ...prevFormData,
      tags: [...prevFormData.tags, tag.trim()],
    }));
    setTag("");
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name == "currency_code") {
      let countryCode = "";
      const selectedOption = e.target.selectedOptions
        ? e.target.selectedOptions[0]
        : null;
      countryCode = selectedOption ? selectedOption.getAttribute("cid") : "";
      setFormData({
        ...formData,
        country_code: countryCode.toString(),
        [name]: type === "checkbox" ? checked : value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
    logMessage(formData);
  };

  const handleRemoveTag = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      tags: prevFormData.tags.filter((_, i) => i !== index),
    }));
  };

  const handleDateChange = (e, name) => {
    logMessage(e.target.value.toString());
    setFormData({
      ...formData,
      [name]: e.target.value.toString(),
    });
  };

  const uploadImageClick = (e) => {
    e.preventDefault();
    logMessage("clicked");
    document.getElementById("imageUploadInput").click();
  };

  return (
    <div className="full_page_inner">
      <Header />
      <div className="detail_outer_container" style={{ overflow: "auto" }}>
        <div className="detail_inner_container addeditscreen">
          <div className="detail_left_container">
            <div className="top_container">
              <h3>Edit Project</h3>
              {/* <input
                type="text"
                className="barcodeinput"
                placeholder="Enter barcode"
                name="barcode"
                value={barcode}
                onChange={handleBarcodeInputChange}
              ></input> */}
              {/* <button>Submit</button> */}
              {/* <img
                onClick={handleScanClick}
                alt="project_image"
                src={imagePaths.scanner}
              ></img> */}
              {/* <img
                onClick={handleOpenCamera}
                alt="project_image"
                src={imagePaths.scanner}
              ></img> */}
              {/* <img alt="project_image" src={imagePaths.scanner}></img> */}
              {isOpen && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                    }}
                  >
                    {/* <button
                      style={{ position: "absolute", top: "5px", right: "5px" }}
                      onClick={handleClose}
                    >
                      Close
                    </button> */}
                    {/* <h2>QR Scanner Component</h2> */}
                    {/* <QrReader
                                            delay={300}
                                            onError={handleError}
                                            onScan={handleScan}
                                            style={{ width: '100%' }}
                                        /> */}
                    {/* <BarcodeScanner onError={handleError} onScan={handleScan} /> */}
                    {/* <h5 style={{textAlign:'center'}}>Enter Barcode</h5> */}
                    {/* <p>Please enter barcode manually in the field</p> */}
                    {/* <input type="text" className="barcodeinput" placeholder="Enter barcode" name="barcode"></input> */}
                    {/* <button>Submit</button> */}
                  </div>
                </div>
              )}
            </div>
            <div
              className="middle_container"
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              {localImage.length > 0 ? (
                <ImageSlider images={localImage} />
              ) : (
                <img alt="project_image" src={imagePaths.tree} />
              )}
              <h3 className="dragClass">Drag or Upload Design Image</h3>
              <p>Four images max. Up to 5MB and in png and jpg only</p>
              <button
                type="button"
                className="uploadImageButton"
                onClick={uploadImageClick}
              >
                Upload Image
              </button>
              <input
                type="file"
                id="imageUploadInput"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
              />
            </div>
            <div className="third_container">
              <p>Design Description or Extra Notes:</p>
              <textarea
                onChange={handleChange}
                name="extraNotes"
                value={formData.extraNotes}
                className="designDescription"
              >{formData.extraNotes}</textarea>
            </div>
          </div>
          <div className="detail_right_container">
            <form onSubmit={handleAddProject}>
              <br></br>
              <div className="form_container">
                <div className="form_fields">
                  <label>Design:</label>
                  <input
                    type="text"
                    name="design"
                    value={formData.design}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form_fields">
                  <label>Artist:</label>
                  <input
                    type="text"
                    name="artist"
                    value={formData.artist}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form_fields">
                  <label>Brand:</label>
                  <input
                    type="text"
                    name="brand"
                    value={formData.brand}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form_fields">
                  <label>Canvas Size (CM):</label>
                  <div className="full_container_size">
                    <div className="width_container">
                      <input
                        type="number"
                        name="canvas_width"
                        placeholder="Width"
                        value={formData.canvas_width === 0 ? "" : formData.canvas_width}
                        onChange={handleChange}
                        required
                      />
                      <RxDimensions />
                    </div>
                    <div className="height_container">
                      <input
                        type="number"
                        name="canvas_height"
                        placeholder="Height"
                        value={formData.canvas_height === 0 ? "" : formData.canvas_height}
                        onChange={handleChange}
                        required
                      />
                      <RxDimensions />
                    </div>
                  </div>
                </div>
                <div className="form_fields">
                  <label>Diamond Info:</label>
                  <select
                    className="select_options" 
                    style={{color:'#000'}}
                    name="diamond_type"
                    value={formData.diamond_type}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Shape</option>
                    <option value="square">Square</option>
                    <option value="round">Round</option>
                   
                  </select>
                  <input
                    type="number"
                    name="no_of_color"
                    placeholder="No. of Colours"
                    value={formData.no_of_color}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form_fields checkbox_field">
                  <div className="checkboxContainer">
                    <input
                      type="checkbox"
                      className="add_special"
                      name="special_status"
                      checked={formData.special_status}
                      onChange={handleChange}
                      // required
                    />
                  </div>
                  <label className="specialDiamonds">
                    Add Special Diamonds
                  </label>
                </div>
                {formData.special_status && (
                  <div className="form_fields">
                    <label></label>
                    <input
                      type="number"
                      name="abs"
                      value={formData.abs === 0 ? "" : formData.abs}
                      placeholder="AB's"
                      onChange={handleChange}
                    />
                    <input
                      type="number"
                      name="glow"
                      value={formData.glow === 0 ? "" : formData.glow}
                      placeholder="Glow"
                      onChange={handleChange}
                    />
                    <input
                      type="number"
                      name="crystal"
                      value={formData.crystal === 0 ? "" : formData.crystal}
                      placeholder="Crystal"
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="form_fields">
                  <label>Total Amount of Diamonds:</label>
                  <input
                    type="number"
                    name="total_amount_diamonds"
                    value={formData.total_amount_diamonds === 0 ? "" : formData.total_amount_diamonds}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form_fields">
                  <label>Price:</label>
                  <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    required
                  />
                  <select
                    className="select_options"
                    name="currency_code"
                    value={formData.currency_code}
                    onChange={handleChange}
                  >
                    <option value="">Select Currency </option>
                    {currency.map((singleCurrency) => (
                      <option
                        key={singleCurrency.flag}
                        cid={singleCurrency.country_code}
                        value={singleCurrency.currency_code}
                      >
                        {singleCurrency.flag}
                        {singleCurrency.currency_code}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form_fields">
                  <label>Start and End date:</label>
                  <input
                    type="date"
                    id="startDate"
                    defaultValue={formData.startDate}
                    selected={formData.startDate}
                    onChange={(date) => handleDateChange(date, "startDate")}
                    placeholderText="Start date"
                    // required
                  />
                  <input
                    type="date"
                    id="endDate"
                    defaultValue={formData.endDate}
                    selected={formData.endDate}
                    onChange={(date) => handleDateChange(date, "endDate")}
                    placeholderText="End date"
                    // required
                  />
                </div>
                <div className="form_fields">
                  <label>Tags:</label>
                  <div className="tags_combined">
                    <input
                      type="text"
                      name="tags"
                      value={tag}
                      onChange={handleTagChange}
                    />
                    <span className="addTagButton" onClick={addTag}>
                      +
                    </span>
                  </div>
                </div>
                <div className="form_fields">
                  {formData.tags.length > 0 && (
                    <div className="tags">
                      {formData.tags.map((tag, index) => (
                        <span key={index} className="tag addprojectTags">
                          {tag}{" "}
                          <span
                            onClick={() => handleRemoveTag(index)}
                            className="crosstag"
                          >
                            x
                          </span>
                        </span>
                      ))}
                    </div>
                  )}
                </div>
                <button className="addProject" type="submit">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="slider-container" style={{ color: "black !important" }}>
      {/* <span
        style={{ color: "black !important" }}
        className="nav-button prev-button"
        onClick={handlePrev}
      >
        ❮
      </span> */}
      <img
        src={images[currentIndex]}
        alt={`slide_${currentIndex}`}
        className="slider-image"
      />
      {/* <span
        style={{ color: "black !important" }}
        className="nav-button next-button"
        onClick={handleNext}
      >
        ❯
      </span> */}
      <div className="thumbnails-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`thumbnail_${index}`}
            className={`thumbnail-image ${
              currentIndex === index ? "active" : ""
            }`}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
    </div>
  );
};
export default EditProject;
