import React, { useEffect, useRef, useState } from "react";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import { RxDimensions } from "react-icons/rx";
import Quagga from "quagga";
import Header from "../../components/common/header.js";
import imagePaths from "../../assets/imagePaths.js";
import { logMessage } from "../../utils/helpers.js";
import api from "../../api/api.js";
import { ApiResponse } from "../../models/ApiResponseModel.js";
import paths from "../../utils/constants.js";
import currency from "../../utils/currencyConstants.js";
import { Scan } from "../../models/ScanModel.js";
import "react-barcode-scanner/polyfill";
import { useSymbologyScanner } from "@use-symbology-scanner/react";
import {
  MultiFormatReader,
  BarcodeFormat,
  DecodeHintType,
  RGBLuminanceSource,
  BinaryBitmap,
  HybridBinarizer,
} from "@zxing/library";

const AddProject = ({ startLoading, stopLoading }) => {
  useEffect(() => {
    const handlePopState = () => {
      window.location.reload();
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const ref = useRef(null);

  const handleSymbol = (symbol) => {
    // console.log(`Scanned ${symbol}`);
  };
  useSymbologyScanner(handleSymbol, { target: ref });

  const navigate = useNavigate();
  const [tag, setTag] = useState("");
  const [scannedResult, setScannedResult] = useState("");
  const [scannedArea, showScannedArea] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [isOpen, setIsOpen] = useState("");
  const [localImage, setLocalImages] = useState([]);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [bigIntSupported, setBigIntSupported] = useState(true);
  const streamRef = useRef(null);

  useEffect(() => {
    if (typeof BigInt === "undefined") {
      setBigIntSupported(false);
    }

    if (!bigIntSupported) {
      return (
        <div>
          Your browser does not support BigInt, which is required for PDF 417
          decoding.
        </div>
      );
    }
    const startCamera = async () => {
      if (scannedArea) {
        const constraints = {
          video: {
            facingMode: "environment",
            width: { ideal: 1280 },
            height: { ideal: 720 },
          },
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        streamRef.current = stream; // Store the stream reference
      }
    };

    const stopCamera = async () => {
      if (streamRef.current) {
        // console.log("Stopping camera stream...");
        try {
          // Stop all tracks of the media stream
          streamRef.current.getTracks().forEach((track) => {
            track.stop();
          });
          Quagga.offProcessed();
          Quagga.offDetected();
          Quagga.stop();
        } catch (error) {
          console.error("Error stopping camera stream:", error);
        } finally {
          streamRef.current = null;
          if (videoRef.current) {
            videoRef.current.srcObject = null;
          }
          videoRef.current = null;
        }
      } else {
        // console.log("No active camera stream to stop.");
      }
    };

    startCamera();
    return () => {
      stopCamera();
      // Quagga.stop();
    };
  }, [scannedArea]);

  const cancelScanning = () => {
    showScannedArea(false)
    // window.location.reload();
  };

  const scanCode = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    logMessage(imageData);
    const luminanceSource = new RGBLuminanceSource(
      imageData.data,
      canvas.width,
      canvas.height
    );
    const binaryBitmap = new BinaryBitmap(new HybridBinarizer(luminanceSource));

    const reader = new MultiFormatReader();
    const hints = new Map();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, [
      BarcodeFormat.QR_CODE,
      BarcodeFormat.CODE_128,
      BarcodeFormat.EAN_13,
    ]);
    // reader.setHints(hints);

    try {
      const result = reader.decode(binaryBitmap);
      alert(`Code detected: ${result.getText()}`);
      // setScannedArea(false); // Stop the scan after successful detection
    } catch (error) {
      // console.log("No code detected.");
    }
  };

  const startQuaggaScan = () => {
    if (scannedArea) {
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            target: videoRef.current,
            constraints: {
              facingMode: "environment",
            },
          },
          decoder: {
            readers: [
              "code_128_reader",
              "ean_reader",
              "ean_8_reader",
              "code_39_reader",
              "code_39_vin_reader",
              "codabar_reader",
              "upc_reader",
              "upc_e_reader",
              "i2of5_reader",
            ],
          },
        },
        (err) => {
          if (err) {
            console.log(err);
            return;
          }
          Quagga.start();
        }
      );

      Quagga.onDetected(async (data) => {
        logMessage("codecode"+data.codeResult.code); 
        await showScannedArea(false);
        setScannedResult(data.codeResult.code);
      });
    }
  };

  useEffect(() => {
    let intervalId;
    if (scannedArea) {
      // intervalId = setInterval(() => {
      startQuaggaScan();
      // }, 3000);
      // return () => clearInterval(intervalId);
    }
  }, [scannedArea]);

  const [formData, setFormData] = useState({
    design: "",
    image: [],
    artist: "",
    brand: "",
    canvas_width: 0,
    canvas_height: 0,
    diamond_type: "",
    no_of_color: "",
    abs: 0,
    glow: 0,
    crystal: 0,
    special_status: false,
    country_code: "",
    currency_code: "",
    total_amount_diamonds: 0,
    price: 0,
    extraNotes: "",
    startDate: "",
    endDate: "",
    tags: [],
  });

  const handleClose = () => {
    setIsOpen(false);
    setScannedResult("");
  };

  const handleScanClick = (e) => {
    if (barcode != "") {
      showScannedArea(false);
      setScannedResult(barcode);
    }
  };
  const handleScanViewClick = (e) => {
    showScannedArea(true);
  };
  const handleTagChange = (e) => {
    setTag(e.target.value);
  };
  const handleBarcodeInputChange = (event) => {
    setBarcode(event.target.value);
  };
  const syncScanResults = async () => {
    // console.log("Syncing scan results:", scannedResult);
    const data = {
      barcode: scannedResult,
    };
    startLoading();
    try {
      const response = await api.scanProduct(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status == 1) {
        const scanModel = new Scan(response.data);
        logMessage(scanModel.typeKey);
        setFormData({
          design: scanModel.design,
          image: scanModel.image,
          artist: scanModel.artist,
          brand: scanModel.brand,
          canvas_width: scanModel.canvas_width,
          canvas_height: scanModel.canvas_height,
          diamond_type: scanModel.typeKey || "",
          no_of_color: scanModel.no_of_color,
          abs: scanModel.variants[0]?.abs || 0,
          glow: scanModel.variants[0]?.glow || 0,
          crystal: scanModel.variants[0]?.crystal || 0,
          special_status: scanModel.special_status,
          country_code: scanModel.country_code.toUpperCase(),
          currency_code: scanModel.currency_code.toUpperCase(),
          total_amount_diamonds:
            scanModel.variants[0]?.total_amount_diamonds || 0,
          price: parseFloat(scanModel.variants[0]?.price || 0),
          extraNotes: "",
          startDate: "",
          endDate: "",
          tags: scanModel.tags,
        });
        setLocalImages((prevImages) => [scanModel.image[0]]);
        logMessage(localImage);
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    if (scannedResult) {
      syncScanResults();
    }
  }, [scannedResult]);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          image: [...prevFormData.image, file],
          // image: [file],
        }));
        logMessage(formData);
        setLocalImages((prevImages) => [...prevImages, e.target.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          image: [...prevFormData.image, file],
          // image: [file],
        }));
        setLocalImages((prevImages) => [...prevImages, e.target.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddProject = async (event) => {
    logMessage(formData);
    event.preventDefault();
    startLoading();
    try {
      const response = await api.addProject(formData);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status == 1) {
        // logMessage(response.data.brand);
        // logMessage("ID");
        logMessage(response.data._id);
        // const signUpResponse = new UserData(response.data);
        stopLoading();
        // localStorage.setItem('user_data', JSON.stringify(signUpResponse.data));
        // localStorage.setItem('user_email', signUpResponse.email);
        // localStorage.setItem('user_name', signUpResponse.name);
        // localStorage.setItem('profile_picture', signUpResponse.profile_picture);
        // navigate(paths.PROJECT_SCREEN);
        navigate(paths.PROJECT_DETAIL + "?project_id=" + response.data._id);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };

  const addTag = (e) => {
    e.preventDefault();
    if (tag.trim() !== "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        tags: [...prevFormData.tags, tag.trim()],
      }));
      setTag("");
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name == "currency_code") {
      let countryCode = "";
      const selectedOption = e.target.selectedOptions
        ? e.target.selectedOptions[0]
        : null;
      countryCode = selectedOption ? selectedOption.getAttribute("cid") : "";
      setFormData({
        ...formData,
        country_code: countryCode.toString(),
        [name]: type === "checkbox" ? checked : value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
    logMessage(formData);
  };

  const handleRemoveTag = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      tags: prevFormData.tags.filter((_, i) => i !== index),
    }));
  };

  const handleDateChange = (e, name) => {
    logMessage(e.target.value.toString());
    setFormData({
      ...formData,
      [name]: e.target.value.toString(),
    });
  };

  const uploadImageClick = (e) => {
    e.preventDefault();
    logMessage("clicked");
    document.getElementById("imageUploadInput").click();
  };

  return (
    <div className="full_page_inner">
      <Header />
      <div className="detail_outer_container" style={{ overflow: "auto", overflowX:"hidden" }}>
        {scannedArea && (
          <div className="full_scan_container">
            <div className="scan_container">
              <h3
                className="scanTitle"
                style={{ textAlign: "center", marginBottom: "-5px" }}
              >
                Barcode Scan
              </h3>
              <p className="scanText" style={{ textAlign: "center" }}>
              Please align your camera to the barcode on the very bottom of your Dreamer Designs box. Note, if scanning is not successful, try moving the barcode closer to your camera.
              </p>
              <div className="videoContainer">
                <video ref={videoRef} style={{ width: "100%" }}></video>
                <canvas
                  ref={canvasRef}
                  style={{ display: "none" }}
                  width="1280"
                  height="720"
                ></canvas>
                <div className="overlay">
                  {" "}
                  <div className="corner top-left"></div>
                  <div className="corner top-right"></div>
                  <div className="corner bottom-left"></div>
                  <div className="corner bottom-right"></div>
                  <div className="scan-line"></div>
                </div>
              </div>

              <button
                onClick={() => cancelScanning()}
                className="addProject cancelScan"
              >
                Cancel Scanning
              </button>
            </div>

            <div className="outer_manual_container">
              <div className="manual_scan_container">
                <p className="barcodeTitle">Or Enter Manually</p>
                <p className="barcodeText">
                Please manually enter barcode below:
                </p>
                <div className="barcodeform">
                  <input
                    type="text"
                    className="barcodeinput"
                    placeholder="Enter barcode"
                    name="barcode"
                    value={barcode}
                    onChange={handleBarcodeInputChange}
                  ></input>
                  <button
                    className="handlescanbutton"
                    onClick={handleScanClick}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {!scannedArea && (
          <div className="detail_inner_container addeditscreen">
            <div className="detail_left_container">
              <div className="top_container">
                <h3>New Project</h3>
                {/* <button>Submit</button> */}
                <img
                  onClick={handleScanViewClick}
                  alt="project_image"
                  src={imagePaths.scanner}
                ></img>
                {/* <img
                onClick={handleOpenCamera}
                alt="project_image"
                src={imagePaths.scanner}
              ></img> */}
                {/* <img alt="project_image" src={imagePaths.scanner}></img> */}
                {isOpen && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px",
                      }}
                    >
                      <button
                        style={{ top: "5px", right: "5px" }}
                        onClick={handleClose}
                      >
                        Close
                      </button>
                      {/* <h2>QR Scanner Component</h2> */}
                      {/* <QrReader
                                            delay={300}
                                            onError={handleError}
                                            onScan={handleScan}
                                            style={{ width: '100%' }}
                                        /> */}
                      {/* <BarcodeScanner 
                    onCapture={handleScan} /> */}
                      <div ref={ref}></div>
                      {/* <h5 style={{textAlign:'center'}}>Enter Barcode</h5> */}
                      {/* <p>Please enter barcode manually in the field</p> */}
                      {/* <input type="text" className="barcodeinput" placeholder="Enter barcode" name="barcode"></input> */}
                      {/* <button>Submit</button> */}
                    </div>
                  </div>
                )}
              </div>
              <div
                className="middle_container"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
              >
                <div className="imagebox">
                  {localImage.length > 0 ? (
                    <ImageSlider images={localImage} />
                  ) : (
                    <img alt="project_image" src={imagePaths.tree} />
                  )}
                </div>
                <h3 className="dragClass">Drag or Upload Design Image</h3>
                <p>Four images max. Up to 5MB and in png and jpg only</p>
                <button
                  type="button"
                  className="uploadImageButton"
                  onClick={uploadImageClick}
                >
                  Upload Image
                </button>
                <input
                  type="file"
                  id="imageUploadInput"
                  accept="image/png, image/jpeg"
                  onChange={handleFileChange}
                />
              </div>
              <div className="third_container">
                <p>Design Description or Extra Notes:</p>
                <textarea
                  onChange={handleChange}
                  name="extraNotes"
                  className="designDescription"
                >
                  {formData.extraNotes}
                </textarea>
              </div>
            </div>
            <div className="detail_right_container">
              <form onSubmit={handleAddProject}>
                <br></br>
                <div className="form_container">
                  <div className="form_fields">
                    <label>Design:</label>
                    <input
                      type="text"
                      name="design"
                      value={formData.design}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form_fields">
                    <label>Artist:</label>
                    <input
                      type="text"
                      name="artist"
                      value={formData.artist}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form_fields">
                    <label>Brand:</label>
                    <input
                      type="text"
                      name="brand"
                      value={formData.brand}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form_fields">
                    <label>Canvas Size (CM):</label>
                    <div className="full_container_size">
                      <div className="width_container">
                        <input
                          type="number"
                          name="canvas_width"
                          placeholder="Width"
                          value={formData.canvas_width === 0 ? "" : formData.canvas_width}
                          onChange={handleChange}
                          required
                        />
                        <RxDimensions />
                      </div>
                      <div className="height_container">
                        <input
                          type="number"
                          name="canvas_height"
                          placeholder="Height"
                          value={formData.canvas_height === 0 ? "" : formData.canvas_height}
                          onChange={handleChange}
                          required
                        />
                        <RxDimensions />
                      </div>
                    </div>
                  </div>
                  <div className="form_fields">
                    <label>Diamond Info:</label>
                    <select
                      className="select_options alloptions"
                      name="diamond_type"
                      value={formData.diamond_type}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Shape</option>
                      <option value="square">Square</option>
                      <option value="round">Round</option>
                      
                    </select>
                    <input
                      type="number"
                      name="no_of_color"
                      placeholder="No. of Colours"
                      value={formData.no_of_color}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form_fields checkbox_field">
                    <div className="checkboxContainer">
                      <input
                        type="checkbox"
                        className="add_special"
                        name="special_status"
                        checked={formData.special_status}
                        onChange={handleChange}
                        // required
                      />
                    </div>
                    <label className="specialDiamonds">
                      Add Special Diamonds
                    </label>
                  </div>
                  {formData.special_status && (
                    <div className="form_fields">
                      <label></label>
                      <input
                        type="number"
                        name="abs"
                        value={formData.abs === 0 ? "" : formData.abs}
                        placeholder="AB's"
                        onChange={handleChange}
                      />
                      <input
                        type="number"
                        name="glow"
                        value={formData.glow === 0 ? "" : formData.glow}
                        placeholder="Glow"
                        onChange={handleChange}
                      />
                      <input
                        type="number"
                        name="crystal"
                        value={formData.crystal === 0 ? "" : formData.crystal}
                        placeholder="Crystal"
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  <div className="form_fields">
                    <label>Total Amount of Diamonds:</label>
                    <input
                      type="number"
                      name="total_amount_diamonds"
                      value={formData.total_amount_diamonds === 0 ? "" : formData.total_amount_diamonds}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form_fields">
                    <label>Price:</label>
                    <input
                      type="number"
                      name="price"
                      value={formData.price === 0 ? "" : formData.price}
                      onChange={handleChange}
                      required
                    />
                    <select
                      className="select_options alloptions"
                      name="currency_code"
                      value={formData.currency_code}
                      onChange={handleChange}
                    >
                      <option value="">Select Currency </option>
                      {currency.map((singleCurrency) => (
                        <option
                          key={singleCurrency.flag}
                          cid={singleCurrency.country_code}
                          value={singleCurrency.currency_code}
                        >
                          {singleCurrency.flag}
                          {singleCurrency.currency_code}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form_fields">
                    <label>Start and End date:</label>
                    <input
                      type="date"
                      selected={formData.startDate}
                      onChange={(date) => handleDateChange(date, "startDate")}
                      placeholderText="Start date"
                      // required
                    />
                    <input
                      type="date"
                      selected={formData.endDate}
                      onChange={(date) => handleDateChange(date, "endDate")}
                      placeholderText="End date"
                      // required
                    />
                  </div>
                  <div className="form_fields">
                    <label>Tags:</label>
                    <div className="tags_combined">
                      <input
                        type="text"
                        name="tags"
                        value={tag}
                        onChange={handleTagChange}
                      />
                      <span className="addTagButton" onClick={addTag}>
                        +
                      </span>
                    </div>
                  </div>
                  <div className="form_fields">
                    {formData.tags.length > 0 && (
                      <div className="tags">
                        {formData.tags.map((tag, index) => (
                          <span key={index} className="tag addprojectTags">
                            {tag}{" "}
                            <span
                              onClick={() => handleRemoveTag(index)}
                              className="crosstag"
                            >
                              x
                            </span>
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                  <button className="addProject" type="submit">
                    Add Project
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="slider-container" style={{ color: "black !important" }}>
      {/* <span
        style={{ color: "black !important" }}
        className="nav-button prev-button"
        onClick={handlePrev}
      >
        ❮
      </span> */}
      <img
        src={images[currentIndex]}
        alt={`slide_${currentIndex}`}
        className="slider-image"
      />
      {/* <span
        style={{ color: "black !important" }}
        className="nav-button next-button"
        onClick={handleNext}
      >
        ❯
      </span> */}
      <div className="thumbnails-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`thumbnail_${index}`}
            className={`thumbnail-image ${
              currentIndex === index ? "active" : ""
            }`}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
    </div>
  );
};
export default AddProject;
