// src/screens/IntroScreen.js

import React, { useState, useEffect } from "react";
import { logMessage } from "../../utils/helpers";
import imagePaths from "../../assets/imagePaths";
import "./authScreen.css";
import { FaEye, FaEyeSlash, FaKey } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import { ApiResponse } from "../../models/ApiResponseModel";
import paths from "../../utils/constants";

const ChangePasswordScreen = ({ startLoading, stopLoading }) => {
  const navigate = useNavigate();
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
        navigate(paths.HOME_SCREEN);
    }
}, [navigate]);

  const changePassword = async (event) => {
    event.preventDefault();

    if (new_password != confirm_password) {
      logMessage("Password mismatch", "alert");
    } else {
      try {
        startLoading();
        if (
          !localStorage.getItem("forgot_email_verify") ||
          localStorage.getItem("forgot_email_verify") == ""
        ) {
          logMessage("Resend otp on previous screen", "alert");
          navigate(paths.FORGOT_PASSWORD_SCREEN);
        }
        logMessage(localStorage.getItem("forgot_email_verify"));
        const data = {
          email: localStorage.getItem("forgot_email_verify"),
          password: new_password,
        };
        const response = await api.changeForgotPassword(data);
        const apiResponse = new ApiResponse(response.status, response.message);
        if (apiResponse.status == 1) {
          logMessage(apiResponse.message, "alert");
          stopLoading();
          localStorage.clear();
          navigate(paths.AUTH_SCREEN);
        } else {
          logMessage(apiResponse.message, "alert");
        }
      } catch (error) {
        logMessage(error);
      } finally {
        stopLoading();
      }
    }
  };
  return (
    <div
      className="intro_screen_container"
      style={{ backgroundImage: `url(${imagePaths.auth_background})` }}
    >
      <div className="intro_content">
        <div className="left_content">
          <img
            className="intro_images"
            src={imagePaths.change_password_side}
            alt="diamond"
          />
        </div>
        <div className="right_content intro_text_data forgotscreen">
          <img src={imagePaths.logOutLogo}></img>
          <div className="forgot_container">
            <h2>New Password</h2>
            <p>Your new password must be unique from those previously used.</p>
            <div className="form-container">
              <form onSubmit={changePassword}>
                <div className="form-item">
                  <FaKey />
                  <input
                    type={showNewPassword ? "text" : "password"}
                    placeholder="New Password"
                    name="new_password"
                    value={new_password}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <button
                    className="passwordEye"
                    type="button"
                    onClick={toggleNewPasswordVisibility}
                  >
                    {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                <div className="form-item">
                  <FaKey />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    name="confirm_password"
                    value={confirm_password}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <button
                    className="passwordEye"
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                <div className="button-group intro-buttons">
                  <button className="colored-button click-buttons primary-button">
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordScreen;
