// src/screens/IntroScreen.js

import React, { useState, useEffect } from "react";
import { logMessage } from "../utils/helpers";
import imagePaths from "../assets/imagePaths";
import introData from "../data/introData"; // Import introData array
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import paths from "../utils/constants";
// import { ReactComponent as IntroThree } from "../images/introsvgthree.svg";
// import { ReactComponent as IntroTwo } from "../images/imagetwosvg.svg";
// import { ReactComponent as IntroFour } from "../images/imagetwosvg.svg";
const IntroScreen = () => {
  const [screenNumber, setScreenNumber] = useState(0);

  const navigate = useNavigate();
  // Function to handle "Next" button click
  const handleNextClick = () => {
    const introContent = document.querySelector(".animate_intro_content");
    introContent.style.opacity = 0;
    introContent.style.transform = "translateX(100%)";
    // setTimeout(() => {
    if (screenNumber < introData.length - 1) {
      setScreenNumber(screenNumber + 1);
    } else {
      navigate("/auth");
    }
    // }, 500);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
        navigate(paths.HOME_SCREEN);
    }
    const introContent = document.querySelector(".animate_intro_content");
    // introContent.style.opacity = 0;
    // introContent.style.transform = "translateX(100%)";

    const timer = setTimeout(() => {
      introContent.style.opacity = 1;
      introContent.style.transform = "translateX(0)";
      introContent.style.transition =
        "opacity ease-in-out, transform 0.5s ease-in-out";
      // introContent.classList.remove('animate-in');
    }, 200);
    return () => clearTimeout(timer);
  }, [screenNumber]);

  const isLastScreen = screenNumber === introData.length - 1;

  const currentScreen = introData[screenNumber];

  const { images_path, heading, subheading, listItems } = currentScreen.content;
  logMessage(images_path);
  return (
    <div
      className="intro_screen_container slide_screen_container"
      style={{ backgroundImage: `url(${imagePaths.intro_background})` }}
    >
      <div className="intro_content animate_intro_content">
        <div className="left_content">
          {screenNumber === 0 && (
            <img src={imagePaths.diamond_img} alt="diamond" />
          )}
          {screenNumber === 1 && (
          //  <IntroTwo className="intro_images"/>
          <img
          className="intro_images introslider"
          src={imagePaths.intro2}
          alt="diamond"
        />
          )}
          {screenNumber === 2 && (
            // <IntroThree className="intro_images"/>
            <img
            className="intro_images introslider"
            src={imagePaths.intro3}
            alt="diamond"
          />
          )}
          {screenNumber === 3 && (
            <img
              // className="intro_images introfourimage"
             className="intro_images introslider"
              src={imagePaths.intro4}
              alt="diamond"
            />
          )}
        </div>
        <div className="right_content intro_text_data">
          {/*  get heading from introData */}
          <h2>{heading}</h2>
          <h3>{subheading}</h3>
          {/*  need a check here to check how many data is in introData listItems and get text from it*/}
          {listItems.map((item, index) => (
            <p>
              <img src={imagePaths.text_icon} alt="Icon" />
              {item}
            </p>
          ))}
          <div className="button-group intro-buttons">
            {isLastScreen ? (
              <Link to="/auth">
                <button className="colored-button click-buttons primary-button">
                  Get Started
                </button>
              </Link>
            ) : (
              <button
                className="colored-button click-buttons primary-button"
                onClick={handleNextClick}
              >
                Next
              </button>
            )}
            {!isLastScreen && (
              <Link to="/auth">
                <button className="no-background-button click-buttons">
                  Skip
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroScreen;
