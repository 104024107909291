import { UserData } from '../models/UserModel';
import paths from './constants';

export const logMessage = (message, type = 'log') => {
  switch (type) {
    case 'log':
      // console.log(message);
      break;
    case 'warn':
      console.warn(message);
      break;
    case 'error':
      console.error(message);
      break;
    case 'alert':
      alert(message);
      break;
    default:
      // console.log(message);
  }
};

export const websiteLink = "https://dreamerdesigns.com/";
export const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const getLocalUserData = () => {
  const storedData = localStorage.getItem('user_data');
  if (storedData) {
    try {

      const parsedData = JSON.parse(storedData);
      if (parsedData && parsedData.data) {
        const userData = new UserData(
          parsedData.data
        );

        return userData;
      } else {
        logMessage("invalid empty data")
        return null;
      }
    } catch (error) {

      logMessage("error parsing data")
      return null;
    }
  } else {
    logMessage("error parsing data", 'warn')
    return null;
  }
};

export const signOutUser = (navigate) => {
  localStorage.clear()
  navigate(paths.AUTH_SCREEN);
}

export const checkAuthorizationToken = (navigate) => {
  const storedData = localStorage.getItem('user_data');
  if (!storedData) {
    navigate(paths.AUTH_SCREEN);
  }
};
export const getLocalUserDataByKey = (key) => {
  const dataKey = localStorage.getItem(key);
  return dataKey;
}
export const formatHoursLogged = (seconds) => {
  const hours = (seconds / 3600).toFixed(1);
  return hours;
}
export const convertSecondsToHours = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  // Pad single-digit values with leading zeros
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

export const convertSecondsToHoursOnly = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
 logMessage(hours)
  return hours;
};
export const convertSecondsToMinutesOnly = (totalSeconds) => {
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  return minutes;
};
export const convertSecondsToSecondsOnly = (totalSeconds) => {
  const seconds = totalSeconds % 60;
  return seconds;
};
export const convertSecondsToHoursInnerCompleted = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;
  // Pad single-digit values with leading zeros
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');
  return `${paddedHours} hours,  ${paddedMinutes} minutes and ${paddedSeconds} seconds`;
};
export const abbreviateNumberDouble = (number) => {
  if (number < 1000) {
    return number;
  } else if (number < 1000000) {
    return (number / 1000).toFixed(1) + 'k';
  } else {
    return (number / 1000000).toFixed(1) + 'M';
  }
};
export const convertSecondsToHoursInner = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  // Pad single-digit values with leading zeros
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${paddedHours}  ${paddedMinutes}  ${paddedSeconds}`;
};

export const convertSecondsToHoursInnerHoursOnly = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  // Pad single-digit values with leading zeros
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${paddedHours}`;
};
export const convertSecondsToHoursInnerMinutesOnly = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  // Pad single-digit values with leading zeros
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${paddedMinutes}`;
};
export const convertSecondsToHoursInnerSecondsOnly = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  // Pad single-digit values with leading zeros
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${paddedSeconds}`;
};
export const convertSecondsToHoursLive = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  // Pad single-digit values with leading zeros
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

export const convertText = (status) => {
  if(status === "not_started"){
    return "Not Started";
  }else if (status === "ongoing"){
    return "In-Progress";
  }else if (status === "completed"){
    return "Completed";
  }

}