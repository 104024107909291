// src/screens/IntroScreen.js

import React, { useState, useEffect } from 'react';
import { logMessage } from '../../utils/helpers';
import imagePaths from '../../assets/imagePaths';
import './authScreen.css';
import { FaEnvelope } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import paths from '../../utils/constants';
import api from '../../api/api';
import { ApiResponse } from '../../models/ApiResponseModel';

const ForgotPasswordScreen = ({ startLoading, stopLoading }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate(paths.HOME_SCREEN);
        }
    }, [navigate]);
    const getOtp = async (event) => {
        event.preventDefault();
        try {
            startLoading();
            const data = {
                "email": email,
                "isLive": false
            };
            const response = await api.sendOtp(data)
            const apiResponse = new ApiResponse(
                response.status,
                response.message
            );
            if (apiResponse.status == 1) {
                stopLoading();
                localStorage.setItem('forgot_email', email);
                navigate(paths.ENTER_OTP_SCREEN);
            } else {
                logMessage(apiResponse.message, 'alert');
            }

        } catch (error) {
            logMessage(error);
        } finally {
            stopLoading();
        }
    };
    return (
        <div className='intro_screen_container' style={{ backgroundImage: `url(${imagePaths.auth_background})` }}>
            <div className="intro_content">
                <div className="left_content">
                    <img className="intro_images" src={imagePaths.forgot_side_image} alt="diamond" />
                </div>
                <div className="right_content intro_text_data forgotscreen">
                    <img src={imagePaths.logOutLogo} ></img>
                    <div className='forgot_container'>
                        <h2>Forgot Password</h2>
                        <p>Don't worry, it happens. Please enter the email address linked to your account.</p>
                        <div className='form-container'>
                            <form onSubmit={getOtp}>
                                <div className="form-item">
                                    <FaEnvelope /> <input type="email" placeholder='Email Address' name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                                <div className='button-group intro-buttons'>
                                    <button className='colored-button click-buttons primary-button'>Get Code</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordScreen;
