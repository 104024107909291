
export const BASE_URL = 'https://api.dreamerdesigns.com';
// export const BASE_URL ='http://localhost:3006';
export const API_URL = 'Och8289v5Aty5S2oUG9PUKhSy2geZh9r3Wum(lhfAREdwEZ*%ZOeyAOa7XsWuOxGWs8KeWHgF*UwEwRjjZzIFaWTVs8R0s5m%9';

export const AUTH_BASE = '/auth';
export const SIGNUP = AUTH_BASE+'/signup';
export const LOGIN = AUTH_BASE+'/login';
export const SEND_OTP = AUTH_BASE+'/sendOtp';
export const VERIFY_OTP = AUTH_BASE+'/verifyOtp';
export const CHANGE_FORGOT_PASSWORD = AUTH_BASE+'/changeForgotPassword';
export const GET_PROJECTS_LIST = '/getProjectList';
export const GET_PROJECTS_DETAIL = '/getProjectDetail';
export const SEARCH_PROJECT = '/searchAndSortProjects';
export const START_TIMER = '/startTimer';
export const STOP_TIMER = '/endTimer';
export const GET_HOME_DATA = '/getHomeData';
export const ADD_PROJECT = '/addProject';
export const EDIT_PROFILE = AUTH_BASE+'/editProfile';
export const GET_PROFILE = AUTH_BASE+'/getProfile';
export const CHANGE_PASSWORD = AUTH_BASE+'/changePassword';
export const SCAN_PRODUCT = '/scanProduct';
export const DELETEACCOUNT = '/deleteAccount';
export const DELETEPROJECT = '/deleteProject';
export const EDITPROJECT = '/editProject';
export const EDITTIMER = '/editTimer';
export const UPDATEPROJECTSTATUS = '/updateProjectStatus';

