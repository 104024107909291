import React, { useState } from 'react';
import Header from '../../components/common/header';
import imagePaths from '../../assets/imagePaths';
import { PiLinkSimpleDuotone } from "react-icons/pi";
import CopyToClipboard from 'react-copy-to-clipboard';
import { websiteLink } from '../../utils/helpers';

const Shop = () => {
    const [textToCopy, setTextToCopy] = useState(websiteLink);

    return (
        <div className='full_page_inner'>
            <Header />
            <div className='dashboard_container'>
                <div className="shop_container">
                    <div className='innerData'>
                        <p className='shopTitle'>Ready for your next diamond painting?</p>
                        <img className="shopImage" src={imagePaths.dreamer_logo_new}></img>
                        <div className='innerDataBox'>
                            <p>Link to website</p>
                            <div className='inputAndIcon'>
                                <PiLinkSimpleDuotone/>
                            <input type="text" value="https://dreamerdesigns.com/" name="website_link" readOnly></input>
                            </div>
                            <p className='redirecttitle'>Link will direct you to the Dreamer Designs shop.</p>
                            <div className='buttons_group'>
                            <CopyToClipboard text={textToCopy}>
                                <span className='copyLink'>Copy Link</span>
                                </CopyToClipboard>
                                <button className="addProject openlink" type="submit"><a target="_blank" href="https://dreamerdesigns.com/">Open Link</a></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Shop;